import { Component, OnInit, HostListener, ElementRef, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @ViewChild("navbar", {static: false}) navbar: ElementRef;

  navbarTogglerActive : boolean;

  constructor(private renderer: Renderer2) {
    this.navbarTogglerActive = false;
   }

  ngOnInit() {
    if(window.scrollY >= 240) {
      this.putFullBackground();
    }
  }

  @HostListener("window:scroll", ['$event'])
  showBackground($event : Event) {
    if(window.scrollY <= 240) {
      this.addBackground();
    } else {
      this.putFullBackground();
    }
  }

  setBackground() {
    if (this.navbarTogglerActive) {
      if(window.scrollY <= 240) {
        setTimeout(() => {
          this.addBackground();
        }, 400);
      }
      this.navbarTogglerActive = false;
    } else {
      this.putFullBackground();
      this.navbarTogglerActive = true;
    }    
  }

  private addBackground() {
    let opacity = (window.scrollY / 3) / 100;
    this.renderer.setStyle(this.navbar.nativeElement, "background-color", 'rgba(0, 0, 0, ' + opacity + ')');
  }

  private putFullBackground() {
      this.renderer.setStyle(this.navbar.nativeElement, "background-color", 'rgba(0, 0, 0, .8)');
  }

}
