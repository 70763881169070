import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { MenuComponent } from './menu/menu.component';
import { BannerComponent } from './banner/banner.component';
import { DividerComponent } from './divider/divider.component';
import { FooterComponent } from './footer/footer.component';
import { AlertComponent } from './alert/alert.component';
import { ProjectCardComponent } from './project-card/project-card.component';

@NgModule({
  declarations: [
    BannerComponent,
    DividerComponent,
    FooterComponent,
    AlertComponent,
    MenuComponent,
    ProjectCardComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule
  ], exports: [
    BannerComponent,
    DividerComponent,
    FooterComponent,
    AlertComponent,
    MenuComponent,
    ProjectCardComponent
  ]
})
export class SharedModule { }
