import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SolicitudeService } from "./services/solicitude.service";
// import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from "./shared/shared.module";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-me/about-me.component';
import { ContactComponent } from './contact/contact.component';
import { Page404Component } from './page404/page404.component';
import { HomeProjectsComponent } from './home/home-projects/home-projects.component';
import { SolicitudeComponent } from './contact/solicitude/solicitude.component';
import { WhomAreWeComponent } from './about-me/whom-are-we/whom-are-we.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    ContactComponent,
    Page404Component,
    HomeProjectsComponent,
    SolicitudeComponent,
    WhomAreWeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [SolicitudeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
