import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() title : string;
  @Input() urlImage : string;

  @ViewChild("bannerBackground", {static: false}) bannerBackground: ElementRef;

  bannerBarra : number;
  bannerPosicion : number;
  bannerAnchoVentana : number;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.bannerBarra = 0;
    this.bannerPosicion = 0;
    this.bannerAnchoVentana = window.innerWidth;
  }

  @HostListener("window:scroll", ['$event'])
  moveBannerImage($event : Event) {
    if (this.bannerAnchoVentana > 576) {
      this.bannerBarra = window.scrollY;
      this.bannerPosicion = this.bannerBarra * 0.5;
      this.renderer.setStyle(this.bannerBackground.nativeElement, "background-position", '0 -' + this.bannerPosicion + 'px');
    } else {
      this.renderer.setStyle(this.bannerBackground.nativeElement, "background-position", '0 -0px');
    }
  }

}
