import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  template: `
  <div class="alert alert-{{ alertType }}" role="alert">
    {{ content }}
  </div>
  `
})
export class AlertComponent implements OnInit {

  @Input() content : string;
  @Input() alertType : string;  //primary, success, danger

  constructor() { }

  ngOnInit() {
  }

}
