import { Component, OnInit } from '@angular/core';
import { Solicitude } from 'src/app/models/solicitude';
import { SolicitudeService } from "../../services/solicitude.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-solicitude',
  templateUrl: './solicitude.component.html',
  styleUrls: ['./solicitude.component.css'],
  animations: [
    trigger('showAlert', [
      state('show', style({
        height: '*',
        opacity: '1',
        // display: 'block'        
      })),
      state('hide', style({
        height: '0px',
        opacity: '0',
        overflow: 'hidden',
        // display: 'none'
      })),
      transition('show => hide', [
        animate('1s')
      ]),
      transition('hide => show', [
        animate('0.5s')
      ])
    ])
  ]
})
export class SolicitudeComponent implements OnInit {
  
  public form : FormGroup;
  
  public isShownEmailError : boolean = false;
  public isShownDescriptionError : boolean = false;
  public isShownSuccessMessage : boolean = false;
  public isShownSendingMessage : boolean = false; 
  public emailErrorMessage : string = "";
  public descriptionErrorMessage : string = "";
  public successMessage : string = "";
  public sendingMessage : string = "Enviando sulicitud.";

  constructor(private solicitudeService : SolicitudeService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loadFormValidations();
  }

  private loadFormValidations() {
    this.form = this.formBuilder.group({
      name: [
        ""
      ],
      email: [
        "", //Valor por defecto
        [Validators.required, Validators.email, Validators.minLength(10)]
      ],
      description: [
        "",
        [Validators.required, Validators.minLength(3)]
      ]
    });
  } 

  createSolicitude(solicitude : Solicitude) {
    this.isShownSendingMessage = true;
    this.solicitudeService.saveSolicitude(solicitude).subscribe(
      data => {        
        this.emailErrorMessage = "";
        this.isShownEmailError = false;
        this.descriptionErrorMessage = "";
        this.isShownDescriptionError = false;
        this.isShownSendingMessage = false;
        this.successMessage = data["message"];

        this.form.reset();

        this.isShownSuccessMessage = true;
        setTimeout(() => this.isShownSuccessMessage = false, 5000)
      },
      error => {
        let message = error.error.errors;
        this.isShownSendingMessage = false;

        if (message.hasOwnProperty('email')) {
          this.emailErrorMessage = message.email[0];
          this.isShownEmailError = true;
        } else {
          this.emailErrorMessage = "";
          this.isShownEmailError = false;
        }

        if (message.hasOwnProperty('description')) {
          this.descriptionErrorMessage = message.description[0];
          this.isShownDescriptionError = true;
        } else {
          this.descriptionErrorMessage = "";
          this.isShownDescriptionError = false;
        }
      }
    );
  }
}
