import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() text: string;
  @Input() link: string;
  @Input() subtitle: string;
  @Input() technologies: string;
  @Input() country: string;
  constructor() { }

  ngOnInit() {
  }

  onClick() {
    if (this.link !== '#') {
      window.open(this.link, "_blank");
    }
  }
}
