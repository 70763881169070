import { Injectable } from '@angular/core';
import { Solicitude } from "../models/solicitude";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SolicitudeService {

  API_URI : string = 'https://antonioalvarez.me/api';

  constructor(private http: HttpClient) { }

  saveSolicitude(solicitude: Solicitude) {
    return this.http.post(`${this.API_URI}/solicitude`, solicitude);
  }
}
